import React, { useContext, useEffect, useState } from "react";
import { Redirect } from "react-router";
import { useLocation, useHistory } from "react-router-dom";
import jwt from 'jwt-decode';

import AuthContext from '../contexts/auth';

export default function AuthSuccess(){
    const { doLogin } = useContext(AuthContext);
    const location = useLocation();
    const history = useHistory();
    useEffect(()=>{
        let params = new URLSearchParams(location.search);
        const token = decodeURIComponent(params.get("token"));
        if(token == null || token === undefined || token === "null"){
            history.push("/");
        }
        else{
            doLogin(token);
            const user = jwt(token).user;
            if(user.isTeacher){
                history.push("/groups");
            }
            else{
                history.push(`/student/${user.id}`)
            }
        }
    }, []);
    return(
        <>
        </>
    );
}
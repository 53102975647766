import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import React from 'react'
import {AuthProvider} from './contexts/auth';
import Router from './routes';

class App extends React.Component {
  render(){
    return (
        <div className="App">
          <AuthProvider>
            <Router />
          </AuthProvider>
        </div>
    );
  }
}

export default App;

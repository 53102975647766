import React, { useEffect, useContext, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Button, Spinner } from "react-bootstrap";
import { ArrowReturnLeft, Link, CheckCircle, XCircle, ExclamationCircle } from "react-bootstrap-icons";
import * as marked from "marked";
import jwt from "jwt-decode";

import AuthContext from '../contexts/auth';
import Item from '../components/Item';
import './main.css';
import config from '../config';

export default function Student(){
    const goToGitLab = "Open GitLab page";
    const goToExternalPage = "Open external page";
    const [data, setData] = useState(null);
    const [mergeRequests, setMergeRequests] = useState([]);
    const [error, setError] = useState("");
    const [notes, setNotes] = useState([]);
    const [assignedMRs, setAssignedMRs] = useState([]);
    const {user, doLogout} = useContext(AuthContext);
    const location = useLocation();
    const history = useHistory();
    const temp = user.token ? jwt(user.token).user : null;
    const User = temp != null ? {isTeacher : temp.isTeacher} : null;
    const id = parseInt(location.pathname.split("/")[2]);
    const findLinkIssue = (idProject, iid) => {
        const fetchData = async () => {
            const response = await fetch(`${config.backEnd}/api/issue/${idProject}/${iid}`,{
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            });
            const responseJSON = await response.json();
            return Promise.resolve(responseJSON);
        }
        const web_url = fetchData();
        return web_url;
    }

    const findLinkMR = (idProject, iid) => {
        const fetchData = async () => {
            const response = await fetch(`${config.backEnd}/api/merge_request/${idProject}/${iid}`,{
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            });
            const responseJSON = await response.json();
            return Promise.resolve(responseJSON);
        }
        const web_url = fetchData();
        return web_url;
    }

    const findImage = async (idProject, upload_link) => {
        const fetchData = async () => {
            const response = await fetch(`${config.backEnd}/api/image/${idProject}`,{
                headers: {
                    'Authorization': `Bearer ${user.token}`,
                    'Ulink': encodeURIComponent(upload_link)
                }
            });
            const responseText = await response.text();
            return Promise.resolve(decodeURIComponent(responseText));
        }
        const web_url = fetchData();
        return web_url;
    }

    const updatedMarkDown = async (text, idProject) => {
        if(text != null && text !== undefined){
            if(text.includes("-/merge_requests/")){
                let regexp = RegExp('(<a) (href=".*-/merge_requests/.*">).*(\\d)(</a>)','g');
                text = text.replaceAll(regexp, `$1 rel="noreferrer" title="${goToGitLab}" target="_blank"$2!$3$4`);
            }
            if(text.includes(`<a href="`)){
                let regexp = RegExp('<a href="(.*)*">','g');
                text = text.replaceAll(regexp, `<a href="$1" rel="noreferrer" title="${goToExternalPage}" target="_blank">`);
            }
            if(text.includes("#")){
                let regexp = RegExp('(.)?#(\\d+)(.)?','g');
                const matches = text.matchAll(regexp);
                for (const match of matches) {
                    console.log(match)
                    if(match[1] === '&') continue;
                    let iid = match[2];
                    const web_url = await findLinkIssue(idProject, iid);
                    text = text.replace(match[0], `${match[1] !== undefined ? match[1] : ''} <a href=${web_url} title="${goToGitLab}" rel="noreferrer" target="_blank">#${iid}</a> ${match[3] !== undefined ? match[3] : ''}`)
                }
            }
            if(text.includes("@")){
                let regexp = RegExp('@(\\w*.\\w*)','g');
                text = text.replaceAll(regexp, `<a href="https://git.kpi.fei.tuke.sk/$1" title="${goToGitLab}" rel="noreferrer" target="_blank">@$1</a>`);
            }
            if(text.includes("<img")){
                let regexp = RegExp(`<img[^>]+src="([^">]+)(" alt="([^">]+)">)`, 'g');
                const matches = text.matchAll(regexp);
                for (const match of matches) {
                    const web_url = await findImage(idProject, match[1]);
                    text = text.replace(match[0], `<img src="${web_url}${match[2]}`)
                }
            }
            if(text.includes("!")){
                let regexp = RegExp('!(\\d*)','g');
                const matches = text.matchAll(regexp);
                for (const match of matches) {
                    if(match[1] === "") continue;
                    const web_url = await findLinkMR(idProject, match[1]);
                    text = text.replace(match[0], `<a href=${web_url} title="${goToGitLab}" rel="noreferrer" target="_blank">!${match[1]}</a>`)
                }
            }
            return Promise.resolve(text);
        }
        return null;
    }

    const markDownNotes = async (notes, idProject) => {
        if(notes != null && notes !== undefined){
            let temp = [];
            for (let i = 0; i < notes.length; i++) {
                let notesTemp = [];
                for(let j = 0; j < notes[i].notes.length; j++){
                    const note = notes[i].notes[j];
                    let body = await updatedMarkDown(marked(note.body), idProject);
                    let regexp = RegExp('(\\d\\d\\d\\d)-(\\d\\d)-(\\d\\d)T(\\d\\d):(\\d\\d):(\\d\\d).\\d{3}[+-](\\d\\d):(\\d\\d)', 'g');
                    const dateTime = note.created_at.replace(regexp, `$3.$2.$1 $4:$5:$6`);
                    regexp = RegExp('(<pre><code class="language-suggestion).*(">)(.|\\n)*?(</code></pre>)', 'g');
                    body = body.replaceAll(regexp,`<p class="language-suggestion">There are several suggestions for the code. Click the link on the right to view details.</p>`);
                    regexp = RegExp(`(<pre)(><code>)([^<]*)(</code></pre)>`, 'g');
                    body = body.replaceAll(regexp,`$1 class="code"$2$3$4`);
                    notesTemp.push(
                        <div key={note.id} className="item">
                            <div className="body">
                                <p className="text" dangerouslySetInnerHTML={{ __html : body}}></p>
                                <p className="dateTime">Created at: {dateTime}</p>
                            </div>
                            <a title={goToGitLab} className="link" href={`${notes[i].web_url_MR}#note_${note.id}`} rel="noreferrer" target="_blank"><Link height="32" width="32" /></a>
                        </div>
                    );
                }
                const title = await updatedMarkDown(notes[i].title_MR, idProject);
                temp.push(
                    <div key={notes[i].id_MR} className="piece">
                        <h5 dangerouslySetInnerHTML={{ __html : `Merge Request: ${title} (<a title="${goToGitLab}" href="${notes[i].web_url_MR}" rel="noreferrer" target="_blank">!${notes[i].iid_MR}</a>)`}}></h5>
                        <div>{notesTemp}</div>
                    </div>
                )
            }
            setNotes(temp);
        }
    }

    useEffect(()=>{
        const fetchData = async () => {
            const response = await fetch(`${config.backEnd}/api/member/${id}`,{
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            });
            const responseJSON = await response.json();
            if(responseJSON.error){
                setError(responseJSON.message)
            }
            else {
                setData(responseJSON)
                if(responseJSON.mergeRequests != null && responseJSON.mergeRequests !== undefined){
                    let tempMergeRequests = [];
                    for(let mergeRequest in responseJSON.mergeRequests){
                        let temp = await updatedMarkDown(marked(responseJSON.mergeRequests[mergeRequest].description), responseJSON.idProject);
                        let description = temp === "" ? "<b>There is no description to this merge request</b>" : temp;
                        temp = await updatedMarkDown(responseJSON.mergeRequests[mergeRequest].title, responseJSON.idProject);
                        let title = `Title: ${temp}`;
                        temp = [];
                        if(responseJSON.mergeRequests[mergeRequest].commits != null && responseJSON.mergeRequests[mergeRequest].commits !== undefined){
                            for (var i = 0; i < responseJSON.mergeRequests[mergeRequest].commits.length; i++) {
                                temp.push(<Item key={responseJSON.mergeRequests[mergeRequest].commits[i].id} value={responseJSON.mergeRequests[mergeRequest].commits[i]} />);
                            }
                        }
                        let commits = temp;
                        let regexp = RegExp('(\\d\\d\\d\\d)-(\\d\\d)-(\\d\\d)T(\\d\\d):(\\d\\d):(\\d\\d).\\d{3}[+-](\\d\\d):(\\d\\d)', 'g');
                        let created_at = null, created_at_mark = null, merged_at = null, merged_at_mark = null;
                        if(responseJSON.mergeRequests[mergeRequest].created_at != null){
                            created_at = responseJSON.mergeRequests[mergeRequest].created_at.replace(regexp, `$3.$2.$1 $4:$5:$6`);
                            let date = new Date(responseJSON.mergeRequests[mergeRequest].created_at);
                            let markDate = new Date(2021, 3, 19, 0, 0, 0);
                            created_at_mark =  date > markDate;
                        }
                        if(responseJSON.mergeRequests[mergeRequest].merged_at != null){
                            merged_at = responseJSON.mergeRequests[mergeRequest].merged_at.replace(regexp, `$3.$2.$1 $4:$5:$6`);
                            let date = new Date(responseJSON.mergeRequests[mergeRequest].merged_at);
                            let markDate = new Date(2021, 4, 8, 0, 0, 0);
                            merged_at_mark =  date > markDate;
                        }
                        tempMergeRequests.push(<div key={responseJSON.mergeRequests[mergeRequest].id} className="object mergeRequest">
                            <div className="title">
                                <h5 dangerouslySetInnerHTML={{ __html : title }}></h5>
                                <a title={goToGitLab} className="link" href={responseJSON.mergeRequests[mergeRequest].web_url} rel="noreferrer" target="_blank"><Link height="32" width="32" /></a>
                            </div>
                            <h6>Description:</h6>
                            <p dangerouslySetInnerHTML={{ __html : description }} className="description"></p>
                            <h6>Created at: {created_at_mark ? <b>{created_at}</b> : created_at} {created_at_mark && <ExclamationCircle className="warning" style={{ marginTop: "-0.15em"}}/>}</h6>
                            <h6>Merged at: {merged_at ? merged_at_mark ? <b>{merged_at}</b> : merged_at : "Not merged"} {merged_at_mark && <ExclamationCircle className="warning" style={{ marginTop: "-0.15em"}}/>}</h6>
                            <h6>Source branch: {responseJSON.mergeRequests[mergeRequest].source_branch.title} {responseJSON.mergeRequests[mergeRequest].source_branch.title === "master" && <ExclamationCircle className="warning"/>} {responseJSON.mergeRequests[mergeRequest].source_branch.web_url != null && <a style={{display: "inline-block"}} title={goToGitLab} className="link" href={responseJSON.mergeRequests[mergeRequest].source_branch.web_url} rel="noreferrer" target="_blank"><Link /></a>}</h6>
                            <h6>Status latest pipeline: {responseJSON.mergeRequests[mergeRequest].pipelineLast ? <a href={responseJSON.mergeRequests[mergeRequest].pipelineLast.web_url} rel="noreferrer" target="_blank" title={goToGitLab}>{responseJSON.mergeRequests[mergeRequest].pipelineLast.status === "success" ? <CheckCircle className="success"/> : <XCircle className="failure" />}</a> : <XCircle className="failure" />}</h6>
                            <h6>State: {responseJSON.mergeRequests[mergeRequest].state}</h6>
                            <h6>Is linked to Issue: {responseJSON.mergeRequests[mergeRequest].isLinkedToIssue ? <CheckCircle className="success"/> : <XCircle className="failure" />}</h6>
                            <div className="title">
                                <h5>Commits</h5>
                            </div>
                            {commits.length !== 0 ? <div className="items commits">
                                {commits}
                            </div> :
                            <h6 className="empty" style={{marginTop: ".5rem", marginBottom: ".5rem"}}><b>There are no commits in this merge request</b></h6>}
                        </div>);
                    }
                    setMergeRequests(tempMergeRequests)
                }
                if(responseJSON.notes != null && responseJSON.notes !== undefined){
                    markDownNotes(responseJSON.notes, responseJSON.idProject)
                }
                if(responseJSON.MRsAssigned != null && responseJSON.MRsAssigned !== undefined){
                    let tempMRsAssigned = [];
                    for(let mergeRequest in responseJSON.MRsAssigned){
                        const title = await updatedMarkDown(responseJSON.MRsAssigned[mergeRequest].title, responseJSON.idProject);
                        tempMRsAssigned.push(
                            <div key={responseJSON.MRsAssigned[mergeRequest].iid} className="object mergeRequest">
                                <div className="title">
                                    <h5 dangerouslySetInnerHTML={{ __html : `Title: ${title} (<a title="${goToGitLab}" href="${responseJSON.MRsAssigned[mergeRequest].web_url}" rel="noreferrer" target="_blank">!${responseJSON.MRsAssigned[mergeRequest].iid}</a>)`}}></h5>
                                </div>
                                <h6 style={{ marginBottom: "0" }}>Is merge request merged: {responseJSON.MRsAssigned[mergeRequest].state === 'merged' ? <CheckCircle className="success"/> : <XCircle className="failure" />}</h6>
                                {responseJSON.MRsAssigned[mergeRequest].status ? <h6><b>{responseJSON.MRsAssigned[mergeRequest].status}</b> <ExclamationCircle className="warning" style={{ marginTop: "-0.15em"}}/></h6> : ''}
                            </div>
                        );
                    }
                    setAssignedMRs(tempMRsAssigned)
                }
            }
        }
        fetchData();
    }, [id])
    return(
    <>
        {data !== null || error !== '' ?
            error !== '' ?
            <div className="container center">
                <h3>{ error }</h3>
                {User.isTeacher ? <Button title="Go back" className="back" onClick={()=>{ history.goBack(); setError(''); }}>Go back</Button> : <Button title="Logout" className="back" onClick={()=>{ doLogout(); setError(''); }}>Logout</Button>}
            </div> :
            <div className="container">
                <div className="title">
                    <h2>{data.fullName}<a style={{display: "inline-block"}} title={goToGitLab} className="link" href={`https://git.kpi.fei.tuke.sk/${data.username}`} rel="noreferrer" target="_blank"><Link /></a></h2>
                    {User.isTeacher && <Button title="Go back" className="back" onClick={()=>history.goBack()}><ArrowReturnLeft width="24" height="24" /></Button>}
                </div>
                <div className="items">
                    {data.issue ?
                        <div className="object">
                            <div className="title">
                                <h3>Issue</h3>
                                <a title={goToGitLab} className="link" href={data.issue.web_url} rel="noreferrer" target="_blank"><Link height="32" width="32" /></a>
                            </div>
                            <h5>Title: {data.issue.title}</h5>
                            <h6>State: {data.issue.state}</h6>
                        </div> :
                        <div className="object">
                            <div className="title">
                                <h3>Issue</h3>
                            </div>
                            <h5 className="empty"><b>There is no issue linked to {data.fullName}.</b></h5>
                        </div>
                    }
                    {mergeRequests && mergeRequests.length !== 0 ?
                        <div className="object">
                            <div className="title">
                                <h3>Merge Requests</h3>
                            </div>
                            <div className="mergeRequests">
                                {mergeRequests}
                            </div>
                        </div> :
                        <div className="object">
                            <div className="title">
                                <h3>Merge Requests</h3>
                            </div>
                            <h5 className="empty"><b>There is no merge requests linked to {data.fullName}.</b></h5>
                        </div>
                    }
                    {data.notes && data.notes.length !== 0 ?
                        <div className="object">
                            <div className="title">
                                <h3>Comments</h3>
                            </div>
                            <div className="items notes">
                                {notes}
                            </div>
                        </div> :
                        <div className="object">
                            <div className="title">
                                <h3>Comments</h3>
                            </div>
                            <h5 className="empty"><b>There are not comments linked to {data.fullName}.</b></h5>
                        </div>
                    }
                    {data.MRsAssigned && data.MRsAssigned.length !== 0 ?
                        <div className="object">
                            <div className="title">
                                <h3>Assigned merge requests</h3>
                            </div>
                            <div className="mergeRequests">
                                {assignedMRs}
                            </div>
                        </div> :
                        <div className="object">
                            <div className="title">
                                <h3>Assigned merge requests</h3>
                            </div>
                            <h5 className="empty"><b>There is not assigned merge requests to {data.fullName}.</b></h5>
                        </div>
                    }
                </div>
            </div> :
            <div className="container loading">
                <Spinner animation="border" role="status" className="spinner"/>
                <h5>Loading...</h5>
            </div>
        }
    </>
    );
}

import React, { useContext } from 'react';
import { Button, ThemeProvider } from 'react-bootstrap';
import { useHistory } from 'react-router';
import jwt from 'jwt-decode';
import { BoxArrowLeft } from 'react-bootstrap-icons';
import AuthContext from '../contexts/auth';

import "./Header.css";

export default function Header(){
    const {user, doLogout} = useContext(AuthContext);
    const history = useHistory();
    const temp = user.token ? jwt(user.token).user : null;
    const User = temp != null ? {fullName : decodeURIComponent(temp.name)} : null;
    const logout = () => {
        doLogout();
    };
    return(
    <>
        <header className="header">
            {User ?
                temp.isTeacher ?
                <h3 className="link" onClick={()=>{history.push("/groups")}} title="Back to groups">Zadanie 2 predmetu ZSI</h3>
                :
                <h3 className="link" onClick={()=>{history.push(`/student/${temp.id}`)}} title="Back to your page">Zadanie 2 predmetu ZSI</h3>
                :
                <h3>Zadanie 2 predmetu ZSI</h3>}
            {User && <div className="profile">
                        <span>Dobrý deň, {User.fullName}</span>
                        <Button onClick={logout} className="logout" title="Log Out"><BoxArrowLeft height="24" width="24"/></Button>
                    </div>
            }
        </header>
    </>
    );
}
import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import jwt from "jwt-decode";

import AuthContext from '../contexts/auth';

export default function PrivateRoute ({ children, ...rest }) {
    const { user } = useContext(AuthContext)
    const temp = user.token ? jwt(user.token).user : null;
    const User = temp != null;
    return (
      <Route {...rest} render={() => {
        return User === true
          ? children
          : <Redirect to='/' />
      }} />
    )
}
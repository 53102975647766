import React, { useReducer, useEffect } from 'react'

let reducer = (user, newUser) => {
    if (newUser === null) {
        sessionStorage.removeItem("user");
        return initialState;
    }
    return { ...user, ...newUser };
};

const initialState = {
    token: ""
}

const localState = JSON.parse(sessionStorage.getItem("user"));

const AuthContext = React.createContext();

function AuthProvider(props){
    const [user, setUser] = useReducer(reducer, localState || initialState);
    useEffect(()=>{
        sessionStorage.setItem("user", JSON.stringify(user));
    }, [user])
    const doLogin = (token) => {
        setUser({token})
        return true;
    }
    const doLogout = () => {
        setUser(initialState)
    }
    return(<AuthContext.Provider value={{user, doLogin, doLogout}}>{props.children}</AuthContext.Provider>);
}

export {AuthProvider}

export default AuthContext
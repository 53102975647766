import React, { useEffect, useState } from 'react';
import { Link, ExclamationCircle } from 'react-bootstrap-icons';
import { useHistory, useRouteMatch } from 'react-router';

import "./Item.css";
import config from '../config'

function Item(props){
    const goToGitLab = "Open GitLab page";
    const [value, setValue] = useState(null);
    const history = useHistory();
    const match = useRouteMatch();
    const isHierarchical = sessionStorage.getItem('hierarchical') === 'true';
    useEffect(()=>{
        if(match.path === '/groups'){
            let {id, name, web_url} = props.value;
            if(isHierarchical){
                let words = name.split(" ");
                if(words[0] !== 'Opakujúci') name = "".concat(words[0]," ",words[1])
            }
            else{
                name = name.replace(config.mainProject,'');
                console.log(name)
            }
            let temp = {
                id: id,
                name: name,
                web_url: web_url,
                title: isHierarchical ? "Go to teams" : "Go to team members"
            }
            setValue(temp)
        }
        else if(match.path === '/subgroups'){
            let {id, name, web_url} = props.value;
            name = name.replace(config.mainProject,'');
            let temp = {
                id: id,
                name: name,
                web_url: web_url,
                title: "Go to team members"
            }
            setValue(temp)
        }
        else if(match.path === '/team'){
            let {id, name} = props.value
            let temp = {
                id: id,
                name: name,
                title: "Open detailed information about student"
            }
            setValue(temp)
        }
        else if(match.path === '/search'){
            let {id, name} = props.value
            let temp = {
                id: id,
                name: name,
                title: "Open detailed information about student"
            }
            setValue(temp)
        }
        else if(match.path === '/student'){
            let {id, title, created_at, web_url, created_by} = props.value;
            let temp = {
                id: id,
                title: title,
                created_at: created_at,
                web_url: web_url,
                created_by: created_by
            }
            const regex = RegExp('(\\d\\d\\d\\d)-(\\d\\d)-(\\d\\d)T(\\d\\d):(\\d\\d):(\\d\\d).\\d{3}[+-](\\d\\d):(\\d\\d)', 'g');
            temp.created_at = temp.created_at.replace(regex, `$3.$2.$1 $4:$5:$6`);
            setValue(temp)
        }
    },[isHierarchical, match, props.value])
    const getDetailsItem = (id) => {
        if(match.path === '/groups'){
            if(isHierarchical){
                history.push(`/subgroups/${id}`)
            }
            else{
                history.push(`/team/${id}`)
            }
        }
        else if(match.path === '/subgroups'){
            history.push(`/team/${id}`)
        }
        else if(match.path === '/team'){
            history.push(`/student/${id}`)
        }
        else if(match.path === '/search'){
            history.push(`/student/${id}`)
        }
    }
    return(
        <>
        {
            value &&
            (<div className="item">
                <div className="body">
                    { match.path !== '/student' ?
                        <h3 onClick={() => getDetailsItem(value.id)} title={value.title}><span className="item_title">{value.name}</span> {'>'}</h3> :
                        <h6>{value.title}</h6>
                    }
                    { match.path === '/student' && <p className="dateTime">Created at: {value.created_at} {!value.created_by && <span><ExclamationCircle className="warning" style={{ marginTop: "-0.15em"}}/> <span style={{marginLeft: "-0.15em"}}>Some problems with authorship</span> </span>}</p>}
                </div>
                { match.path === '/groups' || match.path === '/student' || match.path === '/subgroups'
                    ? <a className="link" title={goToGitLab} href={value.web_url} rel="noreferrer" target="_blank"><Link height="32" width="32" /></a>
                    : null }
            </div>)
        }
        </>
    );
}

export default Item;
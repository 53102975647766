import React, { useEffect, useState, useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Button, Spinner } from "react-bootstrap";
import { ArrowReturnLeft } from "react-bootstrap-icons";

import AuthContext from '../contexts/auth';
import Item from '../components/Item';
import './main.css';
import config from '../config';

export default function Team(){
    const [data, setData] = useState([]);
    const [error, setError] = useState('');
    const { user, doLogout } = useContext(AuthContext)
    const location = useLocation();
    const history = useHistory();
    const id = parseInt(location.pathname.split("/")[2]);
    useEffect(()=>{
        const fetchData = async () => {
            const response = await fetch(`${config.backEnd}/api/team/${id}`,{
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            });
            const responseJSON = await response.json()
            if(responseJSON.error){
                setError(responseJSON.message)
            }
            else{
                if(responseJSON.length === 0){
                    setError("Team is empty, contact with Sergej Chodarev")
                }
                else setData(responseJSON)
            }
        }
        fetchData();
    }, [])
    let members = [];
    if(data.length !== 0 ){
        for (var i = 0; i < data.length; i++) {
            members.push(<Item key={data[i].id} value={data[i]} />);
        }
    }
    return(
    <>
        { data.length !== 0 || error !== '' ?
            error === '' ?
                <div className="container">
                    <div className="title">
                        <h2>Team</h2>
                        <Button className="back" onClick={()=>history.goBack()}><ArrowReturnLeft width="24" height="24" /></Button>
                    </div>
                    <div className="items">
                        { members.length!==0 ? members : null }
                    </div>
                </div> :
                <div className="container center">
                    <h3>{ error }</h3>
                    {error === 'Access fail' ? <Button title="Logout" className="back" onClick={()=>{ doLogout(); setError(''); }}>Logout</Button> : <Button title="Go back"className="back" onClick={()=>{ history.goBack(); setError(''); }}>Go back</Button>}
                </div> :
            <div className="container loading">
                <Spinner animation="border" role="status" className="spinner"/>
                <h5>Loading...</h5>
            </div>
        }

    </>
    );
}
import React from "react";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";

import Login from '../pages/Login';
import Groups from '../pages/Groups';
import SubGroups from '../pages/SubGroups';
import Student from '../pages/Student';
import Team from '../pages/Team';
import SearchResult from '../pages/SearchResult';
import AuthSuccess from '../pages/AuthSuccess';

import Header from '../components/Header';
import Footer from '../components/Footer';

import PrivateRoute from './PrivateRoute';

export default function Routes(){
    return(
        <Router>
            <Header />
            <Switch>
                <Route path="/" exact component={Login}  />
                <Route path="/success" exact component={AuthSuccess} />
                <PrivateRoute path="/groups">
                    <Groups />
                </PrivateRoute>
                <PrivateRoute path="/subgroups">
                    <SubGroups />
                </PrivateRoute>
                <PrivateRoute path="/team">
                    <Team />
                </PrivateRoute>
                <PrivateRoute path="/student">
                    <Student />
                </PrivateRoute>
                <PrivateRoute path="/search">
                    <SearchResult />
                </PrivateRoute>
                <Route component={Login} />
            </Switch>
            <Footer />
        </Router>
    );
}
import React, { useEffect, useState, useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Button, Spinner } from "react-bootstrap";
import { ArrowReturnLeft } from "react-bootstrap-icons";
import jwt from 'jwt-decode';

import AuthContext from '../contexts/auth';
import Item from '../components/Item';
import './main.css';
import config from '../config';

export default function SearchResult(){
    const [data, setData] = useState([]);
    const [error, setError] = useState('');
    const { user, doLogout } = useContext(AuthContext)
    const location = useLocation();
    const history = useHistory();
    const temp = user.token ? jwt(user.token).user : null;
    const User = temp != null ? {isTeacher : temp.isTeacher} : null;
    let params = new URLSearchParams(location.search);
    const name = decodeURIComponent(params.get("name")).trim().replace('\\s\\s+/g', ' ');
    useEffect(()=>{
        const fetchData = async () => {
            const response = await fetch(`${config.backEnd}/api/search?name=${encodeURIComponent(name)}`,{
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            });
            const responseJSON = await response.json()
            if(responseJSON.error){
                setError(responseJSON.message)
            }
            else{
                if(responseJSON.length === 0){
                    setError(`There are no students with name "${name}"`)
                }
                else setData(responseJSON)
            }
        }
        fetchData();
    }, [])
    let members = [];
    if(data.length !== 0 ){
        for (var i = 0; i < data.length; i++) {
            members.push(<Item key={data[i].id} value={data[i]} />);
        }
    }
    return(
    <>
        <div className="container">
            {User.isTeacher && <div className="title">
                <h3>Search results for the name "<i>{name}</i>"</h3>
                <Button className="back" onClick={()=>history.goBack()}><ArrowReturnLeft width="24" height="24" /></Button>
            </div>}
            {data.length !== 0 || error !== '' ?
            error === '' ?
                <div className="items">
                    {members}
                </div> :
                <div className="container center">
                    <h3>{ error }</h3>
                    {error === 'Access fail' ? <Button title="Logout" className="back" onClick={()=>{ doLogout(); setError(''); }}>Logout</Button> : <Button title="Go back"className="back" onClick={()=>{ history.goBack(); setError(''); }}>Go back</Button>}
                </div> :
            <div className="container loading">
                <Spinner animation="border" role="status" className="spinner"/>
                <h5>Loading...</h5>
            </div>
            }
        </div>
    </>
    );
}

import React, { useContext, useEffect, useState } from "react";
import jwt from "jwt-decode";
import { Spinner, Button } from "react-bootstrap";
import { List } from "react-bootstrap-icons";

import Item from '../components/Item';
import SearchBar from '../components/SearchBar';
import AuthContext from '../contexts/auth';
import './main.css';
import config from '../config';

export default function Groups(){
    const [data, setData] = useState([]);
    const [isHierarchical, setHierarchical] = useState(sessionStorage.getItem('hierarchical') ? sessionStorage.getItem('hierarchical') === 'true' : true);
    const [input, setInput] = useState('');
    const [error, setError] = useState("");
    const {user, doLogout} = useContext(AuthContext);
    const temp = user.token ? jwt(user.token).user : null;
    const User = temp != null ? {id : parseInt(temp.id)} : null;
    const updateInput = async (input) => {
        setInput(input);
    }
    useEffect(()=>{
        if(isHierarchical){
            sessionStorage.setItem('hierarchical', true);
            const fetchData = async () => {
                const response = await fetch(`${config.backEnd}/api/groups/${User.id}`,{
                    headers: {
                        'Authorization': `Bearer ${user.token}`
                    }
                });
                const responseJSON = await response.json();
                if(responseJSON.error){
                    setError(responseJSON.message)
                }
                else{
                    if(responseJSON.length === 0){
                        setError("You don't have groups, contact with Sergej Chodarev")
                    }
                    else setData(responseJSON)
                }
            }
            fetchData();
        }
        else{
            sessionStorage.setItem('hierarchical', false);
            const fetchData = async () => {
                const response = await fetch(`${config.backEnd}/api/subgroups/${User.id}`,{
                    headers: {
                        'Authorization': `Bearer ${user.token}`
                    }
                });
                const responseJSON = await response.json();
                if(responseJSON.error){
                    setError(responseJSON.message)
                }
                else{
                    if(responseJSON.length === 0){
                        setError("You don't have subgroups, contact with Sergej Chodarev")
                    }
                    else setData(responseJSON)
                }
            }
            fetchData();
        }
    }, [isHierarchical, User.id, user.token])
    let groups = [];
    if(data.length !== 0 ){
        for (var i = 0; i < data.length; i++) {
            groups.push(<Item key={data[i].id} value={data[i]} />);
        }
    }
    return(
        <>
            { data.length !== 0 || error !== '' ?
                error === '' ?
                    <div className="container">
                        <div className="title">
                            <h2>Groups</h2>
                            <SearchBar
                                keyword={input}
                                setKeyword={updateInput}
                            />
                            <div className="views">
                                <Button className={`view ${isHierarchical && 'active'}`} title="Hierarchical view" onClick={()=>{setHierarchical(true)}}><List className="list" style={{transform: "rotate(90deg)"}}height="20" width="20" /></Button>
                                <Button className={`view ${!isHierarchical && 'active'}`} title="All teams view" onClick={()=>{setHierarchical(false)}}><List className="list" height="20" width="20" /></Button>
                            </div>
                        </div>
                        <div className="items">
                            { groups.length !==0 ? groups : null }
                        </div>
                    </div> :
                    <div className="container center">
                        <h3>{ error }</h3>
                        {error === 'Access fail' && <Button title="Logout" className="back" onClick={()=>{ doLogout(); setError(''); }}>Logout</Button>}
                    </div> :
                <div className="container loading">
                    <Spinner animation="border" role="status" className="spinner"/>
                    <h5>Loading...</h5>
                </div>
            }
        </>
    )
}
import React, { useState } from "react";
import { Search } from "react-bootstrap-icons";
import { useHistory } from "react-router";

import './SearchBar.css';

const SearchBar = ({keyword, setKeyword}) => {
    const [activeB, setStatus] = useState(false);
    const history = useHistory();
    return (
        <div className="searchBarContainer">
            <input
                className="searchBar"
                key="searchBar"
                value={keyword}
                placeholder={"Search student"}
                onChange={(e) => {
                    setKeyword(e.target.value);
                    if(e.target.value.length > 0) setStatus(true);
                    else setStatus(false);
                }}
                onKeyDown={(e) => {
                    if(e.key === 'Enter')
                        if(e.target.value.length > 0) history.push(`/search?name=${encodeURIComponent(keyword)}`)
                }}
            />
            <a title="Search student" className="searchBarSearch" href={`/search?name=${encodeURIComponent(keyword)}`} style={{visibility: activeB ? 'visible' : 'hidden'}}><Search height="20" width="20" style={{marginLeft: "0.5rem", marginRight: "0.5rem"}}/></a>
        </div>
    )
}

export default SearchBar;
import React from 'react';

import "./Footer.css";

export default function Footer(){
    return(
        <>
            <footer className="footer">
                <h4>Vytvorené počas práce na bakalárskom projekte<br/>Vitalii Nikolaienko, Sergej Chodarev, 2020-2021 </h4>
            </footer>
        </>
    );
}
import React from "react";

import './main.css';
import config from '../config';

export default function Login(){
    const auth = config.backEnd + "/auth";
    return(
        <>
            <div className="container center">
                <a href={auth} className="btn btn-primary">Prihláste sa pomocou účtu GitLab</a>
            </div>
        </>
    );
}